import Block from "@/components/wrappers/block"
import styles from "./number-focus.module.css"
import classNames from "classnames"

export default function Number({ block, settings }) {
  const { attrs } = block
  const unit = attrs.unit === "custom" ? attrs.customUnit : attrs.unit
  const number =
    parseInt(attrs.number) > 999
      ? new Intl.NumberFormat("sv-SE").format(attrs.number)
      : attrs.number
  const isWider = attrs.number.length + unit.length >= 10
  const isWide = attrs.number.length + unit.length >= 5
  const theme = block?.attrs?.theme ?? "default"
  const themeClass =
    theme === "accent" ? "has-accent-color" : "has-" + theme + "-theme"

  return (
    <Block
      block={block}
      gap="none"
      className={classNames(
        styles.number,
        themeClass,
        "numbers-" + Math.min(3, settings?.siblings),
        { "is-wide": isWide, "is-wider": isWider }
      )}>
      <div className="number-wrapper">
        <span>{number}</span>
        <span className="unit">{unit}</span>
      </div>
      <div className="text" dangerouslySetInnerHTML={{ __html: attrs?.text }} />
    </Block>
  )
}
